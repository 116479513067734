export const contentTableConfig = {
  propList: [
    { prop: 'name', label: '现金券名称', minWidth: '150', slotName: 'name' },
    { prop: 'hasThreshold', label: '类型', minWidth: '100' ,slotName: 'hasThreshold'},
    // { prop: 'shopname', label: '归属门店', minWidth: '150' },
    {
      prop: 'discount',
      label: '面值',
      minWidth: '100',
      slotName: 'discount'
    },
    {
      prop: 'salePrice',
      label: '售价',
      minWidth: '100',
      slotName: 'salePrice'
    },
    {
      prop: 'validityType',
      label: '使用有效期',
      minWidth: '250',
      slotName: 'validityType'
    },
    {
      prop: 'total',
      label: '剩余 | 总量',
      minWidth: '120',
      slotName: 'total'
    },
    {
      prop: 'use',
      label: '已领取 | 已使用',
      minWidth: '120',
      slotName: 'use'
    },
    { label: '操作', minWidth: '280', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
