<template>
  <div class="top-content">
    <div class="left">
      <el-button class="add-staff" type="primary" @click="handleAddClick" v-has="'coupons:add'">新增{{ ruleForm.type==1?'现金':ruleForm.type==2?'折扣':ruleForm.type==3?'兑换':'现金' }}券</el-button>
    </div>
    <div class="right">
      <!-- <el-select @clear="handleClear" clearable @change="handleTypeChange" v-model="ruleForm.type" class="m-2"
        placeholder="营销券类型" size="large">
        <el-option v-for="item in couponTypeList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select> -->
      <div>
        <el-input @clear="handleClear" clearable v-model="ruleForm.name" class="search" prefix-icon="search"
          placeholder="请输入关键字" />
      </div>

      <div class="search-wrap">
        <el-button class="add-staff" type="primary" @click="handleSearchClick">筛选</el-button>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import { react } from '@babel/types';
import { defineComponent, reactive, ref } from 'vue'
import { couponTypeList } from '../../config'
import emitter from '@/utils/eventbus';
import { useRouter,useRoute } from 'vue-router';

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const ruleForm = reactive({
      type: route.query.type
    })

    const handleSearchClick = () => {
      emitter.emit('refreshCashCouponsList', ruleForm)
    }

    const handleAddClick = () => {
      router.push({
        path: '/main/coupons/add',
        query: {
          type: route.query.type
        }
      })
    }

    return {
      ruleForm,
      couponTypeList,
      handleSearchClick,
      handleAddClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;

  &:deep(.el-select) {
    margin-right: 10px;
  }

  .left {}

  .right {
    display: flex;

    .search-wrap {
      margin-left: 10px;
    }
  }

}
</style>




