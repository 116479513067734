<template>
  <div class="coupons-list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <topContent></topContent>
      <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane v-for="(item,index) in tabStatusList" :key="index" :label="item.label" :name="item.value">
        </el-tab-pane>
        <couponsList :activeName="activeName"></couponsList>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import topContent from './cpns/top-content/top-content.vue'
import couponsList from './cpns/coupons-list/coupons-list.vue'
import { useRouter,useRoute } from 'vue-router'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    topContent,
    couponsList
  },
  setup() {
    const route = useRoute()
    const activeName = ref(0)
    const tabStatusList = reactive([{
      value: 0,
      label: '全部'
    },{
      value: 1,
      label: '未开始'
    },{
      value: 2,
      label: '进行中'
    },{
      value: 3,
      label: '已结束'
    }])
    const handleClick = () => {
    }
    const initPage = () =>{
      switch (route.query.type) {
        case '1':
          breadcrumbList[2].name='现金券列表'
          break;
        case '2':
          breadcrumbList[2].name='折扣券列表'
          break;
        case '3':
          breadcrumbList[2].name='兑换券列表'
          break;
      
        default:
          breadcrumbList[2].name='现金券列表'
          break;
      }
    }
    initPage()

    return {
      breadcrumbList,
      activeName,
      tabStatusList,
      handleClick
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  min-width: 1080px;

  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;

    &:deep(.demo-tabs) {
      margin-top: 20px;
    }
  }
}
</style>




