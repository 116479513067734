<template>
  <div class="extension-dialog">
    <pageDialog
      title="推广"
      width="980px"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <div class="main-content">
        <div class="left_code">
          <canvas id="QRCode" style="width: 180px; height: 180px"></canvas>
          <el-button @click="downloadCode" type="primary">下载推广码</el-button>
        </div>
        <div class="center">
          <div class="left" ref="poster">
            <div class="top">
              <div class="base-info">
                <img/>
                <div class="c-name">一指阁推拿中心</div>
                <div class="desc">为您挑选了一个好物</div>
              </div>
            </div>
            <div class="bottom-content">
              <div class="b-center">
                <div class="b-category">{{ currentCouponItem.type==1?'现金券':currentCouponItem.type==2?'折扣券':'兑换券' }}</div>
                <div class="b-qname">{{ currentCouponItem.name }}</div>
                <div v-if="currentCouponItem.type==1">
                  <div class="b-desc">{{currentCouponItem.hasThreshold?'订单满'+currentCouponItem.thresholdPrice+'减'+currentCouponItem.discount+'元':'无门槛'}}</div>
                </div>
                <div v-else-if="currentCouponItem.type==2">
                  <div class="b-desc">{{currentCouponItem.hasThreshold?'订单满'+currentCouponItem.thresholdPrice+'打'+currentCouponItem.discount+'折':'无门槛'}}</div>
                </div>
                <div class="b-desc" v-else>凭此券免费兑换指定商品</div>
                <div class="use-time">
                  <template v-if="currentCouponItem.validityType == 1">
                    {{
                      currentCouponItem.startDate + "至" + currentCouponItem.endDate
                    }}有效
                  </template>
                  <template v-else-if="currentCouponItem.validityType == 2">
                    {{ currentCouponItem.days }}天内有效
                  </template>
                  <template v-else> {{ currentCouponItem.days + 1 }}天内有效 </template>
                </div>
              </div>
              <div class="b-bottom">
                <img :src="ruleForm.link" alt="" />
                <div class="tips">长按识别二维码</div>
              </div>
            </div>
          </div>
          <el-button @click="downloadImage" type="primary">下载海报</el-button>
        </div>
        <div class="right">
          <div class="lianjie">
            <div class="title">分享标题:</div>
            <el-input
              v-model="ruleForm.link"
              disabled
              :placeholder="ruleForm.link"
              class="input-with-select"
            >
              <template #suffix>
                <el-button @click="handleCopyClick" plain>复制</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </div>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,nextTick } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { TUI_GUANG_BAC_IMG, TUI_GUANG_BAC_IMG2 } from '@/common/constant'
import { ElMessage } from 'element-plus'
import { setTuiguangImg } from '@/service/main/base'
import QRCode from "qrcode"; //引入生成二维码插件
import html2canvas from "html2canvas";
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    currentCouponItem: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }

    const qrUrl = ref('')
    const getQRCode = (id) => {
      qrUrl.value=`${id}`
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 180, //宽
        height: 180, //高
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };
      nextTick(() => {
        let msg = document.getElementById("QRCode");
        // 将获取到的数据(val)画到msg(canvas)上
        QRCode.toCanvas(msg, qrUrl.value, opts, function (error) {
          if (error) {
            console.log("二维码加载失败", error);
            ElMessage({
              message: '二维码加载失败!',
              type: 'error',
            })
          }
        });
      })
    }

    const downloadCode = () => {
      let myCanvas = document.getElementsByTagName('canvas');
      let a = document.createElement('a');
      a.href = myCanvas[0].toDataURL('image/URL');
      a.download = "二维码";
      a.click();
    }

    const poster =ref();
    const posterimg = ref();
    const downloadImage = () => {
      //    document.querySelector() document.getElementById
      // html2canvas(绑定的元素,{})
      // 海报图默认是  2倍尺寸
      html2canvas(poster.value,{
          backgroundColor:'#f5f5f5',//海报的背景颜色
          useCORS:true, // 允许跨域 
          width:280, //生成海报的w
          height:460, //生成海报的h    默认是px
      }).then(canvas=>{
          // canvas 其实就是我们所讲的res 的意思 返回报文的意思
          let  baseImg = canvas.toDataURL("image/png");
          posterimg.value =baseImg;
          let save  = document.createElement('a');
          // <a href=''></a>
          save.href = baseImg;
          // 下载的名字
          save.download = '海报图片'
          // 直接回调a的点击事件
          save.click()
      })
    }

    const handleCopyClick = () => {
      // 创建输入框元素
      let oInput = document.createElement('input');
      // 将想要复制的值
      oInput.value = ruleForm.link;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      ElMessage({
        message: '复制成功!',
        type: 'success',
      })
      // 复制后移除输入框
      oInput.remove();
    }

    const ruleForm = reactive({
      link: 'https://blog.csdn.net/daguanjia11/article/details/119826175',

    })

    const initPage=async()=>{
      let item=props.currentCouponItem
      getQRCode(item.id)
      const res=await setTuiguangImg(item.id)
      ruleForm.link=res.data
      console.log(ruleForm.link);
    }
    initPage()

    return {
      handleCopyClick,
      changeDialogVisible,
      TUI_GUANG_BAC_IMG,
      TUI_GUANG_BAC_IMG2,
      ruleForm,
      qrUrl,
      getQRCode,
      downloadCode,
      downloadImage,
      poster,
      posterimg,
    }

  }
})
</script>

<style scoped lang="less">
.extension-dialog {
  .main-content {
    display: flex;
    min-height: 510px;

    .right {
      flex: 1.2;

      .lianjie {
        display: flex;
        align-items: center;

        .title {
          white-space: nowrap;
          margin-right: 10px;
        }
      }
    }
    .left_code{
      min-height: 510px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 300px;
      align-items: center;
      &:deep(.el-button){
        margin-top: 160px;
        width: 150px;
      }
    }
    .center{
      min-height: 510px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 300px;
      align-items: center;
      margin: 0 30px;
      &:deep(.el-button){
        margin-top: 20px;
        width: 150px;
      }
    }

    .left {
      // flex: 1;
      background: url("https://img01.yzcdn.cn/upload_files/2021/07/13/Fg-U6Ne5BmAP1yJ1J34-ykMPornu.jpg")
        no-repeat;
      background-size: 100% 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      min-height: 460px;
      width: 280px;

      .bottom-content {
        background-color: #fff;
        border-radius: 20px;
        flex: 1;
        padding: 20px;

        .b-bottom {
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 100px;
          }

          .tips {
            margin-top: 16px;
            font-size: 12px;
            color: rgb(49, 49, 49);
          }
        }

        .b-center {
          height: 54%;
          background: url("https://b.yzcdn.cn/mei-pc/81abc11bd66d832617b55e9a37055d77.png")
            no-repeat;
          background-size: 100% 100%;
          position: relative;
          text-align: center;

          & > div {
            display: flex;
            justify-content: center;
            color: white;
          }

          .use-time {
            position: absolute;
            bottom: 2vh;
            color: rgb(17, 17, 17);
            font-size: 12px;
            width: 100%;
          }

          .b-category {
            margin-top: 22px;
            display: inline-block;
            border: 1px solid white;
            padding: 2px 6px;
          }

          .b-qname {
            padding-top: 16px;
            font-size: 22px;
          }

          .b-desc {
            padding-top: 16px;
          }
        }

        .b-bottom {
        }
      }

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .avatar {
          font-size: 32px;
        }

        .base-info {
          margin-left: 10px;

          .c-name {
            font-size: 15px;
            color: white;
            margin-bottom: 4px;
          }

          .desc {
            color: rgb(247, 246, 246);
            font-size: 12px;
          }
        }
      }
    }

    &:deep(.el-input__suffix) {
      right: 0 !important;
    }
  }
}
</style>
