export const breadcrumbList = [
  {
    name: '营销管理',
    path: ''
  },{
    name: '营销列表',
    path: '/main/marketing/list'
  },
  {
    name: '现金券列表',
    path: ''
  }
]

export const couponTypeList = [
  {
    label: '所有',
    value: 0
  },
  {
    label: '现金券',
    value: 1
  },
  {
    label: '折扣券',
    value: 2
  },
  {
    label: '兑换券',
    value: 3
  }
]
