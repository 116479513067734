<template>
  <div class="coupons-list">
    <page-content
      :contentTableConfig="contentTableConfig"
      :isButtonStyle="true"
      :dataCount="dataCount"
      :dataList="dataList"
    >
      <template #hasThreshold="scope">
        <template v-if="!scope.row.hasThreshold"> 无门槛 </template>
        <template v-else>订单满 {{ scope.row.thresholdPrice }}元</template>
      </template>
      <template #total="scope">
        <span>{{ scope.row.surplus }}</span>
        <span class="line">|</span>
        <span>{{ scope.row.total }}</span>
      </template>
      <template #use="scope">
        <el-link :underline="false" type="primary" @click.stop="handleReceiveClick(scope.row)" style="font-size: 14px;">{{ scope.row.receive }}</el-link>
        <span class="line">|</span>
        <el-link :underline="false" type="info">{{ scope.row.used }}</el-link>
      </template>
      <template #validityType="scope">
        <template v-if="scope.row.validityType == 1">
          {{ scope.row.startDate + "至" + scope.row.endDate }}
        </template>
        <template v-else>
          <template v-if="scope.row.validityType == 2">
            领取当天后{{ scope.row.days }}天有效
          </template>
          <template v-else> 领取次日后{{ scope.row.days }}天有效 </template>
        </template>
      </template>
      <template #discount="scope">
        <p>{{ scope.row.type==2?scope.row.discount+'折':'¥'+scope.row.discount}}</p>
      </template>
      <template #salePrice="scope">
        {{ '¥'+scope.row.salePrice||'0.00' }}
      </template>

      <template #handler="scope">
        <div class="handle-btns" v-if="!scope.row.enabled">
          <el-link :underline="false" type="primary" @click.stop="handleRemoveClick(scope.row)" v-has="'coupons:delete'">删除</el-link>
          <span class="border" v-has="'coupons:delete'"> | </span>
          <el-link :underline="false" type="primary" @click.stop="handleCopyClick(scope.row)" v-has="'coupons:copy'">复制</el-link>
        </div>
        <div class="handle-btns" v-else>
          <el-link :underline="false" type="primary" @click.stop="handleDisableClick(scope.row)">停止发放</el-link>
          <span class="border"> | </span>
          <el-link :underline="false" type="primary" @click.stop="handleExtensionClick(scope.row)">推广</el-link>
          <span class="border"> | </span>
          <el-link :underline="false" type="primary" @click.stop="handleEditClick(scope.row)" v-has="'coupons:edit'">编辑</el-link>
          <span class="border" v-has="'coupons:edit'"> | </span>
          <el-dropdown>
            <span class="el-dropdown-link">···</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.stop="handleCopyClick(scope.row)" v-has="'coupons:copy'">复制</el-dropdown-item>
                <el-dropdown-item @click.stop="handleGenerateCodeClick(scope.row)">生成兑换码</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </template>
      <template #pagination>
        <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
          :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </template>
    </page-content>

    <extensionDialog
      v-if="dialogVisible"
      :currentCouponItem="currentCouponItem"
      :dialogVisible="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
    ></extensionDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import { contentTableConfig } from './config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { getCashCouponsList , enabledCoupon , deleteCoupon } from '@/service/main/goods'
import emitter from '@/utils/eventbus'
import { useRouter,useRoute } from 'vue-router'
import extensionDialog from './cpns/extension-dialog/extension-dialog.vue'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import {exportMethod} from '@/utils/export'

export default defineComponent({
  props: {
    activeName: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageContent,
    extensionDialog
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const dataList = ref([])
    const dataCount = ref(0)
    const activeStatus = computed(() => props.activeName)
    const queryInfo = reactive({
      page: 1,
      pagesize: 10,
      type: route.query.type
    })
    watch(activeStatus, (n) => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
    }
    watch(queryInfo, () => {
      initPage()
    }, {
      deep: true
    })

    emitter.on('refreshCashCouponsList', ruleForm => {
      queryInfo.name = ruleForm.name
    })

    onUnmounted(() => {
      emitter.off('refreshCashCouponsList')
    })

    const currentCouponItem = ref()
    const handleExtensionClick = item => {
      currentCouponItem.value = item
      dialogVisible.value = true
      // console.log(item);
    }

    //停止发放
    const handleDisableClick = (item) => {
      popup('确认停止发放该优惠券吗?', async() => {
        let res = await enabledCoupon(item.id)
        if (res.code==0) {
          ElMessage.success({
            message: '操作成功!',
            type: 'success'
          })
          initPage()
        }
      })
    }

    //删除
    const handleRemoveClick = (item) => {
      popup('确认删除该优惠券吗?', async() => {
        let res = await deleteCoupon(item.id)
        if (res.code==0) {
          ElMessage.success({
            message: '删除成功!',
            type: 'success'
          })
          initPage()
        }
      })
    }

    //复制
    const handleCopyClick = (item) => {
      router.push({
        path: '/main/coupons/add',
        query: {
          copyId: item.id,
          type:route.query.type
        }
      })
    }
    //编辑
    const handleEditClick = (item) => {
      router.push({
        path: '/main/coupons/update',
        query: {
          editId: item.id,
          type:route.query.type
        }
      })
    }
    //生成兑换码
    const handleGenerateCodeClick = (item) => {
      exportMethod('/coupon/web/createExchangeCouponNo','兑换券',{couponId:item.id})
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const handleReceiveClick = (item) => {
      router.push({
        path: '/main/coupons/claimDetails',
        query: {
          couponId: item.id
        }
      })
    }

    const initPage = async () => {
      
      switch (queryInfo.type) {
        case '1':
          contentTableConfig.propList[0].label='现金券名称'
          break;
        case '2':
          contentTableConfig.propList[0].label='折扣券名称'
          break;
        case '3':
          contentTableConfig.propList[0].label='兑换券名称'
          break;
      
        default:
          contentTableConfig.propList[0].label='现金券名称'
          break;
      }
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize,
        type:queryInfo.type,
        name:queryInfo.name,
        status:activeStatus.value==0?undefined:activeStatus.value
      }
      const res = await getCashCouponsList(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }
    initPage()

    return {
      currentCouponItem,
      dialogVisible,
      changeDialogVisible,
      dataList,
      dataCount,
      contentTableConfig,
      handleSizeChange,
      handleCurrentChange,
      queryInfo,
      handleEditClick,
      handleExtensionClick,
      activeStatus,
      handleDisableClick,
      handleRemoveClick,
      handleCopyClick,
      handleReceiveClick,
      handleGenerateCodeClick,
      exportMethod
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
}
</style>
